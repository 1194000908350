BODY { background: #fff;font-family: Arial, Helvetica, sans-serif; margin:0px; color: #868887; font-size: 12px; }

a { text-decoration: none; color: #868887 }
a:hover   { text-decoration: underline }

img { border: 0 }
a img { border: 0 }

SELECT    { border:1px solid #000; font-size: 9px; }

/**********************************************************************/
/* New site en 1000px                                                 */
/**********************************************************************/

#top { position: relative;width: 1000px;height: 100px }

#logo { position: absolute;top: 0;left: 0 }

#banniere { position: absolute;top: 10px;left: 400px;z-index: 100 }

#NewC { position: absolute;top: 5px;right: 5px }

#MonCompte {  background: url('/medias/mon_compte.gif') no-repeat;position: absolute;top: 50px;right: 5px;width: 199px;height: 73px;padding-top: 15px }

#menu { background: url('/medias/BoutonGauche.jpg') no-repeat top right;clear: both;width: 1000px;height: 35px;margin: 15px auto 0 auto;overflow: hidden }
#menu ul { margin: 0;padding: 0;list-style: none;font-family: Arial }
#menu li { display: inline;height: 35px;margin: 0;padding: 0 }
#menu a { background: url('/medias/menu1.jpg') no-repeat;display: inline-block;width: 152px;height: 35px;line-height: 35px;margin: 0;padding: 0;text-align: center;color: #00a535;font-weight: bold;font-size: 12px;overflow: hidden;font-family: Verdana }
#menu a:hover { background: url('/medias/menu1-hover.jpg') no-repeat;text-decoration: none;color: #fff }
#menu a.active { background: url('/medias/menu1-hover.jpg') no-repeat;text-decoration: none;color: #fff }
#menu li.first a { background: url('/medias/home.jpg') no-repeat;display: inline-block;width: 35px;height: 35px;line-height: 35px;margin: 0;padding: 0 }
#menu li.first a:hover { background: url('/medias/home-hover.jpg') no-repeat;text-decoration: none;color: #fff }
#menu li.first a.active { background: url('/medias/home-hover.jpg') no-repeat;text-decoration: none;color: #fff }

/* Recherche */
#recherche { position: absolute;bottom: 0;right: 0;background: url('/medias/fond_rech.jpg') repeat-x;width: 187px;height: 35px;overflow: hidden }
#recherche input.rech { background: transparent;width: 138px;height: 20px;line-height: 20px;padding-left: 2px;color: #868887;font-family: Verdana;font-size: 11px;border: 0 }
#recherche div.rech1 { position: absolute;top: 8px;left: 8px }
#recherche div.rech2 { position: absolute;top: 8px;left: 150px;width: 30px;height: 20px }
#recherche div.rech2 input { background: transparent;width: 30px;height: 20px;font-weight: bold;cursor: pointer;border: 0 }

#rech { clear: both;background: url('/medias/font-rech.jpg') repeat-x;width: 1000px }

#contenu { clear: both;display: flex;margin: 0 auto;overflow: auto;padding: 13px 0 15px 0 }

#MenuGauche { width: 185px;padding: 0 }
#MenuGauche p { background: #03a536;width: 165px;height: 35px;line-height: 35px;text-align: left;padding: 0 10px;margin: 0;font-weight: bold;font-size: 14px;color: #fff }
#MenuGauche ul { list-style: none;padding: 0;width: 185px;margin: 0 }
#MenuGauche li { overflow: auto }
#MenuGauche li.rs { background: #edece8;display: block;width: 165px;font-size: 11px;height: 22px;line-height: 22px;margin: 1px 0;padding: 0 5px 0 15px;text-align: left;text-decoration: none }
#MenuGauche li a { background: #edece8;display: block;width: 165px;font-size: 11px;height: 22px;line-height: 22px;margin: 1px 0;padding: 0 5px 0 15px;text-align: left;text-decoration: none }
#MenuGauche li a:hover { text-decoration: none;color: #fff;background: #666666 }
#MenuGauche li a.active { text-decoration: none;color: #fff;background: #666666 }
#MenuGaucheli a.sm { width: 150px;display: block;font-size: 11px;line-height: 14px;margin: 0;padding: 0 5px 0 25px;border: 0 }
#MenuGauche li a.sm:hover { color: #db1b23;border: 0 }
#MenuGauche li a.sm_active { width: 170px;display: block;color: #db1b23;font-size: 14px;font-weight: bold;line-height: 16px;margin: 3px 0;padding-left: 10px;border: 0 }

#Central { margin-left: 20px; width: 100%; }
#Central h1 { background: #ecebe7;padding: 0 10px;height: 35px;line-height: 35px;font-size: 15px;margin: 0 0 10px 0;color: #03a536 }

div.centre { clear: both; overflow: auto }

/* LAYOUT */
#main         { width: 80%;margin: 0 auto }
#container    {  }

/* HEADER */
#header             { margin:0px 0px 0px 0px;	width:100%;	height:90px; background: #EFEFEF; border-width: 0px 0px 1px 0px; border-style: solid; border-color: #00732e; }
#logo               { position:absolute; top:5px; left:5px; display:block;}
#logo a             { display:block; }
#anim               { display:none; position:absolute; top:5px; left:180px;}
#header #titreSite  { height: 50px; margin:0 10px 0 10px; font-size: 26px; font-weight: bold; font-style: italic; text-align: right;  color:#FFF;}


/* LAYOUT CONTENT */
#content        { position:relative; margin:0px; padding:0px; display:block; border:0px solid #000; }
#contentLeft    { position:relative; margin: 0px; padding:0px; width: 180px; float: left; border:0px solid #000; }
#contentRight   { margin: 0px; padding:0px; width: 170px; float: right; border:0px solid #000; }
#contentMiddle  { position:relative; margin:0px; padding:0px; margin-left:180px; border:0px solid #000; }

/* FOOTER */
#footer { clear: both;overflow: auto;width: 100%;overflow: hidden }
#footer2 { clear: both;width: 1000px;overflow: auto;margin-top: 10px;overflow: hidden }
#footer2 div.footer2a { float: left }
#footer2 div.footer2b { float: right;width: 800px }
#footer2 table { width: 100%;border-collapse: collapse }
#footer2 td { width: 200px;vertical-align: top }
#footer2 ul { list-style: none;padding: 0 }
#footer2 li a:hover { color: #333333;text-decoration: none }
#footer2 li.a { background: url('/medias/PuceFooter.png') no-repeat 10px 8px;width: 180px;padding: 0 0 0 20px;line-height: 20px;font-weight: bold;font-size: 10px }
#footer2 li.b { background: url('/medias/PuceFooter2.png') no-repeat 20px 10px;width: 170px;padding: 3px 0 3px 30px;line-height: 12px;font-size: 10px }

#bottomB                          { width: 100%;margin: 5px auto;text-align: center }




#menu_page_static { width:100%; margin-bottom:10px; height:30px;}
#menu_page_static ul, #menu_page_static li { margin:0; padding: 0; list-style-type: none;}
#menu_page_static li { float:left; margin-left:10px; padding:5px;}
#menu_page_static a { display:block; padding:5px; color: #0A50A1; text-decoration:none;	font-weight: bold; font-style:italic;}
#menu_page_static a:hover { display:block; padding:5px; color: #FFF; background-color:#00A0C6; text-decoration:none; font-weight: bold; font-style:italic;}

#menu_page_static #current a { display:block; padding:5px; color: #FFF; background-color:#00A0C6; text-decoration:none; font-weight: bold; font-style:italic;}

#onglet_bar               { position:relative; padding:10px 0px 0px 0px; display:block; height:25px; }
/*#option_bar               { display:block; height:35px; background-image: url(../Templates_client/Images/fond_option_bar.png); background-repeat: no-repeat; background-position: top left; border-width:1px 0px 1px 0px; border-style: solid; border-color: #00732e;}*/

/* SOUS-MENU */
#navig                    { margin:0px; padding:0px; }
#navig a                  { display:block; color: #000; font-weight:bold; padding:3px; }
#navig a:hover            { color: #000; text-decoration: none; }
#navig a:visited          { color: #000;}

/* RECHERCHE */
#recherche                            { padding:0px; margin:0px;  font-family: Arial, Helvetica, sans-serif;font-size: 11px;font-style: normal;line-height: normal;font-weight: bold;font-variant: normal;text-transform: none;color: #FFFFFF;text-decoration: none;}
#recherche a                          { color: #FFFFFF; }
#form_recherche                       { margin:0px; padding:0px; height:5px; }
#recherche #checkbox_search           { padding:0px; margin:0px; }
#recherche #checkbox_search input     { padding:0px; margin:0px; height:15px; }
#recherche #saisie_search             { padding:0px; margin:0px; }
#recherche #saisie_search input       { padding:0px; margin:0px; font-size: 10px; }
#recherche #button_search             { padding:0px; margin:0px; }
#recherche #button_search input       { padding:0px; margin:0px; vertical-align: middle; }
#resultat_recherche_prix              { display: none; }
.saisie_search                        { padding:0px; margin:0px; font-size: 10px; }


/*********************************************************************************************************/
/* Styles CIFFREO pour les Pages statiques                                                               */
/*********************************************************************************************************/
.idref { font-family: Arial, Helvetica, sans-serif; font-size: 10px; font-style: normal; line-height: normal; font-weight: bold; font-variant: normal; text-transform: none; color: #333333; text-decoration: none; }
.minitxtblc { font-family: Arial, Helvetica, sans-serif; font-size: 11px; font-style: normal; line-height: normal; font-weight: bold; font-variant: normal; text-transform: none; color: #FFFFFF; text-decoration: none; }
.txtblkbasebold { font-family: Arial, Helvetica, sans-serif; font-size: 11px; font-style: normal; line-height: normal; font-weight: bold; font-variant: normal; text-transform: none; color: #333333; text-decoration: none; }
.etbdevis { font-family: Arial, Helvetica, sans-serif; font-size: 12px; line-height: normal; font-weight: bold; font-variant: normal; text-transform: none; color: #329B34; text-decoration: none; }
.grdtxtblc { font-family: Arial, Helvetica, sans-serif; font-size: 12px; font-style: normal; line-height: normal; font-weight: bold; font-variant: normal; text-transform: none; color: #FFFFFF; text-decoration: none; }
.titr2ssrub { font-family: Arial, Helvetica, sans-serif; font-size: 16px; font-style: normal; line-height: normal; font-weight: bolder; font-variant: normal; text-transform: none; color: #4D4D4D; text-decoration: none; }
.txtredbold { font-family: Arial, Helvetica, sans-serif; font-size: 11px; font-style: normal; line-height: normal; font-weight: bold; font-variant: normal; text-transform: none; color: #B20F00; text-decoration: none; }
.txtcourantblk { font-family: Arial, Helvetica, sans-serif; font-size: 11px; font-style: normal; line-height: normal; font-weight: normal; font-variant: normal; text-transform: none; color: #000000; text-decoration: none; }
.ssmenugauche { font-family: Arial, Helvetica, sans-serif; font-size: 10px; font-style: normal; line-height: normal; font-variant: normal; color: #333333; text-decoration: none; }
.ssmenugauchred { font-family: Arial, Helvetica, sans-serif; font-size: 12px; font-style: normal; line-height: normal; font-weight: bold; font-variant: normal; color: #AE211A; font-weight: bold; text-decoration: none;}


/* Mon compte */
#ident { position: relative;background-color: #CCC;border-radius: 10px;width: 210px; height: 150px;overflow: auto;margin: 20px auto }
#ident div input { width: 115px; border-radius: 4px; border: 1px solid rgb(179,179,179); }
#ident div.login { position: absolute;top: 18px;right: 20px }
#ident div.mp { position: absolute;top: 51px;right: 20px }
#ident div.valid { position: absolute;bottom: 30px;right: 10px }
#ident div.valid button { padding: 5px; border: 1px solid; outline: none; cursor: pointer; background: linear-gradient(white, #efebea); color: #00a535; font-weight: bold; }
#ident div.valid button:hover { background: #00a535; color: #fff; }
#ident p { position: absolute;bottom: 0;left:0;width: 200px;margin: 0 auto;text-align: right;height: 16px }

#reinitpass { width: 210px; margin: 20px auto; }
#reinitpass button { margin-top: 10px; padding: 5px; border: 1px solid; outline: none; cursor: pointer; background: linear-gradient(white, #efebea); color: #00a535; font-weight: bold; }
#reinitpass button:hover { background: #00a535; color: #fff; }

#InfoContact { width: 100%;text-align: center;font-size: 12px }
#InfoContact p.a { width: 100%;text-align: center;font-size: 16px;font-weight: bold;padding: 0 0 5px 0;margin: 0 }
#InfoContact p.b { width: 100%;text-align: center;padding: 0;margin: 0 }
#InfoContact p.c { width: 100%;text-align: center;padding: 0;margin: 0 }
#InfoContact p.d { width: 100%;text-align: center;padding: 0;margin: 0;color: green }

#MPO { position: relative;width: 300px; height: 150px;overflow: auto;margin: 20px auto }
#MPO p { width: 100%;text-align: center }
#MPO div.login { position: absolute;top: 42px;right: 20px }
#MPO div.valid { position: absolute;bottom: 40px;right: 10px }

#Infos { width: 100% }
#Infos table { width: 380px;margin: 15px auto;border-collapse: collapse;background: #ccc;border: 5px solid #ccc }
#Infos table td { padding: 3px 5px }

#Contact { width: 100% }
#Contact table { width: 580px;margin: 15px auto;border-collapse: collapse;background: #ccc;border: 5px solid #ccc }
#Contact table td { padding: 3px 5px }
#Contact textarea { width: 450px;height: 150px }

#MonCompte2 { padding-bottom: 10px;overflow: auto;font-size: 12px }
#MonCompte2 fieldset { margin-bottom: 10px }
#MonCompte2 input { background: #faffd3;border: 1px solid #ccc;color: #192790;font-weight: bold }
#MonCompte2 table { width: 780px }
#MonCompte2 td { padding: 0 5px;height: 16px;line-height: 16px }
#MonCompte2 td.nfo { background: #c2dfc3;border: 1px solid #ccc;color: #0588d6;font-weight: bold }
#MonCompte2 p { width: 100%;font-weight: bold;text-align: center }

#RechFacture { padding-bottom: 10px;overflow: auto;font-size: 12px }
#RechFacture div { overflow: auto }
#RechFacture div.rech { float: left;height: 110px }
#RechFacture div.rech td.little { font-size: 10px }
#RechFacture div.result { float: right;max-height: 120px;border: 1px solid #ccc; }
#RechFacture div.result table { border-collapse: collapse }
#RechFacture div.result td.a { text-align: left;padding-left: 5px }
#RechFacture div.result td.b { text-align: center;border-left: 1px solid #ccc }
#RechFacture div.result div.table { max-height: 80px;overflow: auto;overflow-x: hidden }
#RechFacture div.result div.table tr.fonce { background: #c2dfc3;cursor: pointer }
#RechFacture div.result div.table tr.clair { background: #fff;cursor: pointer }
#RechFacture div.message { clear: both;height: 100px;margin: 10px auto;background: #c2dfc3;border: 3px ridge #ccc;padding: 5px;font-weight: bold }
#RechFacture div.rlv table { width: 794px;border: 1px solid #ccc;border-collapse: collapse;margin: 5px auto }
#RechFacture div.rlv td { text-align: center;padding: 0 5px;border: 1px solid #ccc }
#RechFacture div.rlv td.a { text-align: left }
#RechFacture div.rlv td.b { text-align: right }
#RechFacture div.rlv tr.fonce { background: #c2dfc3;font-weight: bold }
#RechFacture div.rlv tr.clair { background: #fff;font-weight: bold  }

#Litiges { padding-bottom: 10px;overflow: auto;font-size: 12px }
#Litiges p { width: 100%;text-align: center }
#Litiges div { overflow: auto }
#Litiges div.result { border: 1px solid #ccc;overflow: hidden }
#Litiges div.result table { width: 100%;border-collapse: collapse }
#Litiges div.result td { padding: 0 5px;border: 1px solid #ccc }
#Litiges div.result td.left { text-align: left }
#Litiges div.result td.right { text-align: right }
#Litiges div.result td.center { text-align: center }

#Litiges div.message { clear: both; height: 100px ;background: #c2dfc3;border: 3px ridge #ccc;padding: 5px;font-weight: bold }
.message_popup_container { font-size: 12px; }
.message_popup_container .close_btn_popup { top: 0px; right: 0px; cursor: pointer; }

#Litiges div.rlv { overflow: auto;overflow-x: hidden;height: 300px;max-height: 300px;font-weight: bold }
#Litiges div.rlv table { width: 100%;border-collapse: collapse;margin: 0 auto }
#Litiges div.rlv td { text-align: center;padding: 0 5px;border: 1px solid #ccc }
#Litiges div.rlv td.left { text-align: left }
#Litiges div.rlv td.right { text-align: right }
#Litiges div.rlv td.center { text-align: center }
#Litiges div.rlv tr.fonce { background: #c2dfc3 }
#Litiges div.rlv tr.clair { background: #fff }

#Recap { overflow: auto }
#Recap table { width: 100%;margin: 5px auto;border-collapse: collapse }
#Recap table td { padding: 3px 5px }
#Recap table td span { font-weight: bold;color: red }

/* Cookie banner */
.cookie-banner { position: fixed; text-align: center; background-color: #03a536; width: 100%; z-index: 10000; bottom: 0; left: 0; right: 0; }
.cookie-banner .cookie-message { color: #fff; }
.cookie-banner button { padding: 5px; border: 1px solid; outline: none; cursor: pointer; background: linear-gradient(white, #efebea); color: #00a535; font-weight: bold; }
.cookie-banner button:hover { background: #00a535; color: #fff; }

/* APM */
.apm_container .apm_buttons_utilities button,
.apm_filters_container button,
.apm_new_container button,
.apm_edit_container button,
.apm_popup__container .btn { padding: 10px; border: 1px solid; outline: none; cursor: pointer;background: linear-gradient(white, #efebea); color: #00a535; font-weight: bold; }

.apm_container .apm_buttons_utilities button:hover,
.apm_filters_container button:hover,
.apm_new_container button:hover,
.apm_edit_container button:hover,
.apm_popup__container button:hover { background: #00a535; color: #fff; }

.apm_list_container h2 { display: none; }
.apm_container .apm_filter_status .status_0,
.apm_container .apm_tickets_list .ticket_status_0 { background-color: #ffec48 }
.apm_container .apm_filter_status .status_1,
.apm_container .apm_tickets_list .ticket_status_1 { background-color: #76b7ff }
.apm_container .apm_filter_status .status_2,
.apm_container .apm_tickets_list .ticket_status_2 { background-color: #cc7bda }
.apm_container .apm_filter_status .status_3,
.apm_container .apm_tickets_list .ticket_status_3 { background-color: #ffae39 }
.apm_container .apm_filter_status .status_4,
.apm_container .apm_tickets_list .ticket_status_4 { background-color: #abec6c }
.apm_container .apm_filter_status .status_5,
.apm_container .apm_tickets_list .ticket_status_5 { background-color: #f97474 }
.apm_container .apm_filter_status .status_6,
.apm_container .apm_tickets_list .ticket_status_6 { background-color: #a3a99b }

.apm_list_container .apm_buttons_utilities .btn_new,
.apm_list_container .apm_tickets_list thead .deadline,
.apm_list_container .apm_tickets_list tbody .deadline,
.apm_container .apm_filters_container .priority { display: none; }

.apm_new_container .priority,
.apm_new_container .deadline,
.apm_new_container .source,
.apm_new_container .submit label:first-child { display: none !important; }

.apm_edit_container .edit table thead .keys,
.apm_edit_container .edit table thead .deadline,
.apm_edit_container .edit table tbody .keys,
.apm_edit_container .edit table tbody .deadline,
.apm_edit_container .edit table tbody .follower,
.apm_edit_container .edit table tbody .source,
.apm_edit_container .edit table tbody .priority { display: none; }


.apm-toast {
    width: 300px;
    padding: 10px;
    z-index: 9999;
    position: fixed;
    top: 20px;
    right: 20px;
    border-radius: 5px;
}

.apm-toast p {
    color: #fff;
    font-size: 16px;
    margin: 0;
}

.apm_container { display: flex; }
.apm_container button { padding: 10px; border: 1px solid; outline: none; cursor: pointer; }
.apm_container button + button { margin-left: 10px; }

.apm_list_container { width: 85%; }
.apm_list_container h2 { text-transform: uppercase; }
.apm_list_container .apm_top { display: flex; justify-content: space-between; }
.apm_list_container .apm_filter_types button { margin: 0; padding: 10px; background-color: #ccc; border: none; outline: none; border-bottom: 1px solid; cursor: pointer; border-radius: 5px 5px 0 0; }
.apm_list_container .apm_filter_types button.active { background-color: white; border-top: 1px solid; border-left: 1px solid; border-right: 1px solid; border-bottom: none; }

.apm_list_container .apm_filter_status { display: flex; justify-content: flex-end; margin-top: 20px; }
.apm_list_container .apm_filter_status label { margin-left: 10px; padding: 3px; font-weight: bold; color: black; }

.apm_list_container .apm_tickets_list { border-collapse: collapse; border-spacing: 0; width: 100%; }
.apm_list_container .apm_tickets_list thead tr th { color: black; background-color: #cccccc; height: 30px; }
.apm_list_container .apm_tickets_list td, .apm_tickets_list th { border: 1px solid #ffffff; padding: 5px 8px; }
.apm_list_container .apm_tickets_list tbody tr { cursor: pointer; }
.apm_list_container .apm_tickets_list tbody tr:nth-child(odd) td { background: #ececec; }

.apm_list_container .apm_paging { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.apm_list_container .apm_paging ul { display: flex; justify-content: center; list-style-type: none; margin-bottom: 0; }
.apm_list_container .apm_paging li { position: relative; font-weight: 300; line-height: 1.35; color: #9B9B9B; }
.apm_list_container .apm_paging li + li:before { display: inline-block; content: '|'; margin: 0 3px; }
.apm_list_container .apm_paging a { color: inherit; text-decoration: none; padding: 10px; }
.apm_list_container .apm_paging .paging__spacer { padding: 0 5px; }
.apm_list_container .apm_paging .current a { color: #4A4A4A; font-weight: bold; }

.apm_filters_container { width: 300px; margin-left: 20px; padding: 10px; border-left: 1px solid; }
.apm_filters_container .apm_filters_title { text-align: center; }
.apm_filters_container .form-box { margin-bottom: 10px; }
.apm_filters_container .form-box label { display: block; font-weight: bold; }
.apm_filters_container .form-box input[type=text],
.apm_filters_container .form-box input[type=date] { width: 100%; }